// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

:root {
    // --ion-background-color: #ff3700;
    // --ion-background-color: linear-gradient(226.47deg, #eeeeee 1.25%, #e8e8e8 83.12%);
    --ion-font-family: -apple-system, BlinkMacSystemFont, "Reg_font", sans-serif;
    --ion-text-color: #000000;
    --ion-text-color-rgb: 0, 0, 0;
    /** primary **/
    --ion-color-primary: #c99700;
    --ion-color-primary-rgb: 201, 151, 0;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #ba9217;
    --ion-color-primary-tint: #ddb641;
    /** secondary **/
    --ion-color-secondary: #d9d8d7;
    --ion-color-secondary-rgb: 217, 216, 215;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #bfbebd;
    --ion-color-secondary-tint: #dddcdb;
    /** tertiary **/
    --ion-color-tertiary: #4a4a4a;
    --ion-color-tertiary-rgb: 55, 52, 53;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #414141;
    --ion-color-tertiary-tint: #5c5c5c;
    /** success **/
    --ion-color-success: #7ed321;
    --ion-color-success-rgb: 126, 211, 33;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #6fba1d;
    --ion-color-success-tint: #8bd737;
    /** warning **/
    --ion-color-warning: #c99700;
    --ion-color-warning-rgb: 201, 151, 0;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #b18500;
    --ion-color-warning-tint: #cea11a;
    /** danger **/
    --ion-color-danger: #d0021b;
    --ion-color-danger-rgb: 208, 2, 27;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #b70218;
    --ion-color-danger-tint: #d51b32;
    /** dark **/
    --ion-color-dark: #232323;
    --ion-color-dark-rgb: 34, 34, 34;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1c1c1c;
    --ion-color-dark-tint: #3b3b3b;
    /** medium **/
    --ion-color-medium: #3e3e3e;
    --ion-color-medium-rgb: 62, 62, 62;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #505050;
    --ion-color-medium-tint: #9b9b9b;
    /** light **/
    --ion-color-light: #f2f2f2;
    --ion-color-light-rgb: 244, 244, 244;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d5d5d5;
    --ion-color-light-tint: #f3f3f3;
    // Custom Text Color
    --ion-text-white: #ffffff;
    --ion-text-white-rgb: 255, 255, 255;
    --ion-text-black: #000000;
    --ion-text-black-rgb: 0, 0, 0;
    // Custom Background Color
    --ion-bg-white: #ffffff;
    --ion-bg-white-rgb: 255, 255, 255;
    --ion-bg-black: #000000;
    --ion-bg-black-rgb: 0, 0, 0;
    //width variables
    --ion-content-width: calc(100% - 16px);
    --ion-content-width2: calc(100% - 24px);
    --ion-content-width3: calc(100% - 32px);
    // Custom Button Disabled
    --ion-color-disabled: #a7a9ac;
    //font family variables
    --ion-font-regular: Reg_font;
    --ion-font-semibold: Semi_bold_font;
    --ion-font-bold: Bold_font;
    // Confirm button css
    --ion-confirm-btn-color: #c99700;
    --ion-confirm-btn-disable: #ffffff;
    // membership card css
    --ion-login-sr-section-margin: 0px -15px 0px auto;
    --ion-login-mc-height: 181px;
    --ion-login-sr-section-radius: 5px 0px 0px 5px;
    --memb-particulars-margin: 21px;
    --ion-mc-direction: row;
    --ion-login-user-img-width: 54px;
    --ion-login-user-img-height: 54px;
    --ion-user-img-radius: 50%;
    --ion-user-img-space-right: 16px;
    --ion-user-img-space-bottom: 0px;
    --ion-user-text-align: left;
    --ion-login-user-name-font: 16px;
    --ion-user-name-color: #000000;
    --ion-user-name-opacity: 0.8;
    --ion-font-semibold: Semi_bold_font;
    --ion-login-user-number-margin: 0px;
    --ion-login-user-number-font: 14px;
    --ion-user-number-color: #000000;
    --ion-user-number-opacity: 0.6;
    --meme-card-bottom-details-width: 95%;
    --login-meme-card-bottom-details-height: 34px;
    --meme-card-bottom-details-bg: transparent;
    --meme-card-bottom-details-label-color: #000000;
    --meme-card-bottom-details-label-opacity: 0.5;
    --meme-card-bottom-details-label-padding: 10px 20px;
    --ion-login-mb-max-height: 134px;
    --memb-detail-display: block;
    --ion-login-mc-padding: 50px 15px 0px 18px;
    --ion-awaiting-badge-bg: rgb(201 151 0 / 30%);
    --ion-awaiting-icon-color: #c99700;
    --ion-menu-icon-color: #000000;
}