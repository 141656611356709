// http://ionicframework.com/docs/theming/
@import "~@ionic/angular/css/core.css";
@import "./theme/icons.scss";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/utils.bundle.css";

// Fonts
@font-face {
    font-family: Reg_font;
    src: url("./assets/fonts/Reg_font.otf");
}

@font-face {
    font-family: Semi_bold_font;
    src: url("./assets/fonts/Semi_bold_font.otf");
}

@font-face {
    font-family: Bold_font;
    src: url("./assets/fonts/Bold_font.otf");
}

html.ios {
    --ion-default-font: -apple-system, BlinkMacSystemFont, "Reg_font", sans-serif;
}

ion-menu {
    margin-top: env(safe-area-inset-top);
}

.header-ios ion-toolbar:last-child {
    --border-width: 0px !important;
}

.header-md ion-toolbar:last-child {
    --border-width: 0px !important;
}

.header-md:after {
    background-image: none !important;
}

ion-content {
    --keyboard-offset: 0px !important;
}

.modal-backdrop {
    position: unset !important;
}

.modal {
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.83) !important;
}

div#myModal {
    z-index: 999;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.default-btn {
    margin: 0px 16px;
    box-shadow: none;
    border: 0px;
    height: 58px;
    font-size: 18px;
    --border-radius: 8px 8px 0px 0px;
}

.page-content {
    margin-top: 0;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.default-card,
.sc-ion-card-ios-h.default-card,
.sc-ion-card-md-h.default-card {
    box-shadow: 4px 4px 8px #c2c2c2;
    width: var(--ion-content-width);
    margin-inline-start: 16px;
    margin-inline-end: 16px;
    --background: #fff;
    border-radius: 6px;
    margin: 10px auto;
    background: #fff;
    overflow: hidden;
}

ion-toolbar {
    --background: transparent;
    --ion-color-base: transparent !important;
}

ion-header {
    background: transparent;
    --background: transparent;
}

ion-content {
    --background: linear-gradient(226.47deg, #eeeeee 1.25%, #e8e8e8 83.12%);
    background: linear-gradient(226.47deg, #eeeeee 1.25%, #e8e8e8 83.12%);
}

ion-app {
    --background: linear-gradient(226.47deg, #eeeeee 1.25%, #e8e8e8 83.12%);
    background: linear-gradient(226.47deg, #eeeeee 1.25%, #e8e8e8 83.12%);
}

ion-toolbar {
    --min-height: 56px;
    --min-height: 56px;
}

.link-text {
    --background: transparent !important;
    --background-focused: transparent !important;
    --background-hover: transparent !important;
    --background-activated: transparent !important;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    --padding-start: 0px;
    --padding-end: 0px;
    --border-radius: 0px;
    height: auto;
    margin: 0px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: normal;
    --box-shadow: none;

    ion-icon {
        font-size: 15px;
        margin-right: 5px;
    }
}

.d-flex,
[flex] {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

.backNew {
    width: 24px !important;
}

.height-auto {
    --min-height: auto;
    min-height: auto;
}

ion-card-content.no-padding {
    padding-inline-start: 0;
    padding-inline-end: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.top-title {
    padding-top: 0px;
    padding-bottom: 0px;
    padding-inline-start: 26px;
    padding-inline-end: 16px;
    background: transparent;

    ion-card-subtitle {
        font-weight: 400;
        font-size: 16px;
    }

    ion-card-title {
        font-weight: 400;
        font-size: 22px;
    }
}

ion-card-content {
    padding-inline-start: 15px;
    padding-inline-end: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.w-100 {
    width: 100%;
}

.label-floating.sc-ion-label-ios-h,
.label-stacked.sc-ion-label-ios-h,
.label-floating.sc-ion-label-md-h,
.label-stacked.sc-ion-label-md-h {
    transform: none !important;
}

.help-text {
    font-size: 11px !important;
    color: var(--ion-color-danger);
    margin: 2px 0px !important;
}

.sc-ion-label-md-s h2,
.sc-ion-label-ios-s h2 {
    margin: 0px;
    font-size: 16px;
}

ion-icon[class*="ca-verified"] {
    height: 20px;
    width: 20px;
}

ion-header.inner-header {
    background: #fff;
    --background: #fff;
}

ion-label,
.label-floating.sc-ion-label-md-h,
.label-stacked.sc-ion-label-md-h,
.label-stacked.sc-ion-label-ios-h,
.label-floating.sc-ion-label-ios-h {
    margin: 10px 8px 10px 0px;
}

// label breck in two line
.wrap {
    white-space: normal;
}

ion-item {
    --background: transparent;
    --min-height: 49px;
    --border-color: rgba(151, 151, 151, 0.2);
    --inner-border-width: 0px 0px 1px 0px;
    --border-width: 0;
    --background-activated: transparent;
}

ion-label,
.item .sc-ion-label-ios-h,
.item .sc-ion-label-md-h,
p,
.sc-ion-label-ios-s p,
.sc-ion-label-md-s p {
    font-size: 14px;
    line-height: 1.4;
}

ion-label,
ion-select,
ion-select.sc-ion-select-md-h,
ion-select.sc-ion-select-ios-h,
ion-select.sc-ion-select-md-s,
ion-select.sc-ion-select-ios-s,
ion-input,
ion-input.sc-ion-input-md-h,
ion-input.sc-ion-input-ios-h,
ion-input.sc-ion-input-md-s,
ion-input.sc-ion-input-ios-s,
ion-item,
ion-card,
ion-card.sc-ion-card-ios-h,
ion-card.sc-ion-card-ios-s,
ion-card.sc-ion-card-md-h,
ion-card.sc-ion-card-md-s,
ion-badge,
.item.sc-ion-label-ios-h,
.item .sc-ion-label-ios-h,
.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h,
p,
h2,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--ion-font-regular);
    line-height: 1.4;
}

p,
h2,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
    font-weight: normal;
}

.sc-ion-card-md-s ion-list,
.sc-ion-card-ios-s ion-list {
    padding: 0px;
    margin: 0px;
}

ion-item.item-lines-full,
.list-ios-lines-full .item,
.list-ios .item-lines-full {
    --border-width: 0px 0px 1px 0px;
    --inner-border-width: 0px;
}

ion-item.item-lines-none,
ion-item.item-lines-inset {
    --inner-border-width: 0px;
    --border-width: 0px;
}

ion-button,
.sc-ion-buttons-md-s ion-button,
.sc-ion-buttons-ios-s ion-button {
    font-size: 14px;
    font-family: var(--ion-font-semibold);
    letter-spacing: 0px;
    --box-shadow: none;
    --border-radius: 4px;
    text-transform: initial;
}

ion-button.button-block,
ion-button.button-default {
    margin: 10px auto;
    height: 50px;
    width: var(--ion-content-width);
    font-size: 16px;
}

ion-button.button-outline {
    --border-width: 1px;
}

// date 29-8-19
.curved-style {
    position: relative;

    &:after,
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: var(--ion-bg-white);
        z-index: 99;
    }

    &:after {
        right: -5px;
    }

    &:before {
        left: -5px;
    }
}

// Custom Modal Css
body .modal-wrapper.sc-ion-modal-ios,
body .modal-wrapper.sc-ion-modal-md {
    background: transparent;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    opacity: 1 !important;
    // transition: all 0.4s !important;
}

.modalBg {
    min-height: 100%;
    overflow-y: auto;
    padding: 60px 0 0 0px;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    align-items: flex-end;
}

.modalWrap {
    margin: 0px;
    width: 100%;
    border-radius: 8px 8px 0px 0px;
    box-shadow: 5px 5px 12px 0 rgba(71, 71, 71, 0.5);
    background-color: white;
    box-sizing: border-box;
    overflow-y: auto;
}

.modalHeader {
    position: relative;
    text-align: center;
    border-bottom: 1px solid rgba(151, 151, 151, 0.3);
}

.modalTitle {
    font-size: 18px;
    font-family: var(--ion-font-reguler);
    color: var(--ion-text-color);
}

.modalClose {
    position: absolute;
    right: 15px;
    font-size: 25px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--ion-text-color);

    ion-button {
        color: var(--ion-text-color);
    }
}

.modalButton {
    --border-radius: 0px;
    width: 100% !important;
    margin-bottom: 0px !important;
    height: 60px !important;
    letter-spacing: 0;
    font-size: 16px;
    box-shadow: none;
    font-family: var(--ion-font-semibold);
}

.modalContent.card-content-md,
.card-content-ios.modalContent {
    padding: 10px 0px;

    .formField {
        margin-bottom: 15px;
    }

    ion-item:last-child {
        --border-width: 0;
        --inner-border-width: 0;
    }
}

// Custom Checkbox & Redio box
.custom-check,
.custom-radio {
    position: relative;
    width: auto;
    display: flex;
    align-items: center;
    min-height: 20px;

    input {
        visibility: hidden;
        display: none;

        +label {
            cursor: pointer;
            display: flex;
            padding-left: 40px;
            color: var(--ion-color-medium-shade);

            // position: relative;
            &:focus {
                background: none;
            }

            &:before {
                content: "";
                mask-size: contain;
                mask-position: 50% 50%;
                mask-repeat: no-repeat;
                background: currentColor;
                mask-image: url(assets/icon/rectangle-23-copy-3.svg);
                color: var(--ion-color-primary);
                display: inline-block;
                // border: 1px solid rgba(var(--ion-color-primary-rgb, 201, 151, 0), .4);
                height: 20px;
                padding: 0;
                width: 22px;
                border-radius: 4px;
                margin-right: 18px;
                vertical-align: middle;
                position: absolute;
                left: 0;
                top: 0px;
                text-align: center;
            }
        }

        &:checked {
            +label {
                &:before {
                    content: url(assets/icon/white-tick-small.svg);
                    mask-image: url(assets/icon/group-12.svg);
                }
            }
        }
    }
}

// Accordion Css
.accordion-card {
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);

    ion-item {
        --min-height: 50px;
    }

    ion-label {
        font-size: 16px;
        color: var(--ion-text-black);
    }

    ion-icon {
        font-size: 14px;
    }

    [slot="end"] {
        margin-left: 0px;
    }

    .item-label {
        &.ios {
            .birthdayDate {
                margin-right: 8px;
            }
        }

        .birthdayDate {
            font-size: 18px;
        }
    }
}

// Custom Searchbar Css
.customSearch {
    position: relative;
}

.custom-searchbar {
    --background: transparent !important;
    padding: 8px;
}

.searchbar-input {
    box-shadow: none;
    border: 1px solid lightgray;
    border-radius: 6px;
    font-size: 16px;
    padding: 8px 60px 8px 12px !important;
    margin: 0px;
    height: 48px;
}

.custom-searchbar .searchbar-search-icon {
    left: auto !important;
    right: 12px !important;
    width: 25px !important;
    height: 25px !important;
    top: 50%;
    transform: translateY(-50%);
    display: none;
}

.custom-searchbar .searchbar-clear-button {
    display: none !important;
}

.custom-searchbar .searchbar-input-container {
    height: 46px;
}

.custom-searchbar .searchbar-clear-icon {
    display: none !important;
}

.custom-searchbar .searchbar-input::-ms-clear {
    display: none;
    width: 0;
    height: 0;
}

.custom-searchbar .searchbar-input::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
}

.searchbar-input::-webkit-search-decoration,
.searchbar-input::-webkit-search-cancel-button,
.searchbar-input::-webkit-search-results-button,
.searchbar-input::-webkit-search-results-decoration {
    display: none;
}

.searchIcon {
    position: absolute;
    border-radius: 0px 4px 4px 0px;
    top: 8px;
    height: 48px;
    right: 8px;
    bottom: 0;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 50px;
    background: var(--ion-color-primary);
    border: 1px solid var(--ion-color-primary);

    ion-icon {
        font-size: 18px;
        color: var(--ion-color-primary-contrast);
    }
}

.customSearchbar2 {
    position: relative;

    // margin: 10px 0;
    .item-searchbar {
        --icon-color: var(--ion-color-primary);
        --background: #fff;
        --border-radius: 6px;
        --box-shadow: none;
        font-family: var(--ion-font-regular);
        padding: 10px;

        .searchbar-input {
            border: 0;
            font-size: 14px;
            padding: 6px 60px 6px 10px !important;
            height: 38px;
            font-family: var(--ion-font-regular);
        }

        .searchbar-search-icon {
            top: 10px;
            display: none !important;
        }
    }

    .searchIcon {
        top: 10px;
        height: 38px;
        width: 42px;
    }
}

.member-searchbar.custom-searchbar .searchbar-input {
    font-size: 14px;
    height: 48px;
}

// Center Modal Css
.customModal-text {
    word-wrap: break-word;
    word-break: break-word;

    ion-input {
        word-wrap: normal;
        word-break: normal;
    }
}

.customModal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    opacity: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    transition: opacity 0.15s linear;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.5);

    .customModal-dialog {
        position: relative;
        width: auto;
        // margin: 16px 20px;
        max-width: 85%;
        margin: 16px auto;
        pointer-events: none;
        transition: -webkit-transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
        -webkit-transform: translate(0, -50px);
        transform: translate(0, -50px);
    }

    &.show {
        opacity: 1;
        z-index: 1072;

        .customModal-dialog {
            -webkit-transform: none;
            transform: none;
        }
    }

    .customModal-dialog-centered {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        min-height: calc(100% - 32px);

        &:before {
            display: block;
            height: calc(100% - 32px);
            content: "";
            box-sizing: border-box;
        }
    }

    .customModal-content {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        border-radius: 10px;
        outline: 0;
        overflow: hidden;
    }

    ion-buttons {
        justify-content: flex-end;
        padding: 15px;
        padding-bottom: 5px;
    }

    .customModal-header {
        text-align: right;
        padding: 1rem 1rem;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        position: relative;

        .ca-modal-close {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .customModal-body {
        position: relative;
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        padding: 1rem;
        min-height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-height: 370px;
        overflow-y: auto;
    }

    .customModal-footer {
        // padding-top: 1rem;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        &.two-btn {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            ion-button {
                width: 50%;
                --border-radius: 0;
            }
        }

        ion-button {
            margin: 0px;
            border-radius: 0px 0px 10px 10px;
            height: 50px;
            font-size: 18px;
        }
    }

    .customModalButton {
        margin: 0px;
        border-radius: 0px 0px 10px 10px;
        height: 50px;
        font-size: 18px;
    }
}

//  menu Css
.menuContent {
    --background: white !important;

    ion-icon {
        color: var(--ion-menu-icon-color);
        font-size: 27px;
        margin-right: 20px;

        &.ca-info-icon {
            font-size: 16px;
            color: var(--ion-color-primary);
            margin-right: 10px;
            margin-bottom: 0;
            margin-top: 0;
        }
    }
}

.menuIconSearch {
    font-size: 1.6em !important;
}

.menuBar {
    font-size: 1.6em;
    padding: 20px 0px 0px 12px;
    display: inherit;
}

.logoutclass {
    width: 1em !important;
    height: 1em !important;
}

.menuContent {
    ion-item {
        ion-label {
            font-size: 16px !important;
        }

        .editProfileBtn {
            margin: 0;
            --padding-start: 0;
        }

        .menuEditLabel {
            color: var(--ion-color-primary) !important;
        }

        .menuLogout {
            font-family: var(--ion-font-semibold) !important;
        }
    }

    .menuHeader {
        margin-top: 15px;
    }

    .menuName {
        --inner-padding-top: 20px;
        --inner-padding-bottom: 10px;

        ion-label {
            font-family: var(--ion-font-semibold);
            font-size: 18px !important;
            margin: 0;
        }
    }
}

.celebBackground {
    min-height: 100%;
    overflow-y: auto;
    padding: 60px 0 0 0px;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    width: 100%;
    align-items: flex-end;
}

.input-label {
    font-size: 14px;
    font-family: var(--ion-font-regular);
    color: var(--ion-text-color);
    margin: 0;
    transform: none !important;
}

ion-select .select-placeholder {
    color: #000;
}

.card-content-md,
.card-content-ios {
    padding-inline-start: 16px;
    padding-inline-end: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
    font-size: 14px;
    line-height: 1.3;
}

.card-content-md p,
.card-content-ios p {
    line-height: 1.3;
    font-size: 14px;
}

.welcomepage .swiper-container-horizontal>.swiper-pagination-bullets,
.welcomepage .swiper-pagination-custom,
.welcomepage .swiper-pagination-fraction {
    bottom: 210px;
}

// ion-footer {
//   background: var(--ion-background-color);
// }
.welcomepage ion-footer:before,
.footer-md:before {
    display: none;
}

.border-0 {
    border: 0px !important;
}

ion-select,
ion-input,
ion-datetime {
    position: relative;
}

.timeInputRow ion-select .select-icon {
    display: none;
}

// Android Segement Css
.androidSegment {
    font-size: 16px;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0px;
        width: 100%;
        height: 2px;
        margin-top: -2px;
        border-bottom: 2px solid var(--ion-color-light-shade);
    }

    ion-segment-button {
        --padding-end: 10px;
        --padding-start: 10px;
        //min-width: 90px;
        min-height: 40px;
        letter-spacing: 0em;
        line-height: 30px;
        color: #929292;
        text-transform: initial;
        font-size: 16px;
        position: relative;
        z-index: 1;

        &.segment-button-checked,
        &.activated {
            color: var(--ion-text-color);

            ion-label {
                font-family: var(--ion-font-semibold);
            }
        }

        ion-label {
            margin: 0px;
            text-transform: initial;
        }
    }
}

// ioS Segement Css
.iosSegment {
    margin: 10px auto;
    background: transparent;
    border-radius: 0px;

    ion-segment-button {
        margin: 0 2px;
        border: 1px solid var(--ion-color-primary);
        border-radius: 4px;
        height: 36px;
        padding: 0px 10px;
        min-width: auto;
        --border-width: 0px;
        --border-color: transparent;
        --indicator-height: 0px;
        --indicator-box-shadow: none;

        &.segment-button-checked {
            background: var(--ion-color-primary);
            color: var(--ion-color-primary-contrast);
        }

        ion-label {
            margin: 0px;
        }
    }

    .segment-button-checked::before,
    .segment-button-after-checked::before {
        display: none;
    }
}

.item-nopadding,
.no-padding {
    --padding-start: 0px;
    --inner-padding-end: 0px;
    padding: 0;
}

ion-label.ion-no-margin,
.label-floating.sc-ion-label-md-h.ion-.no-margin,
.label-stacked.sc-ion-label-md-h.ion-no-margin,
.label-stacked.sc-ion-label-ios-h.ion-no-margin,
.label-nomargin,
.ion-no-margin,
[no-margin] {
    --margin-start: 0;
    --margin-end: 0;
    --margin-top: 0;
    --margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}

.custom-height {
    --min-height: 30px;
}

.select-arrow {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 0;
}

.form-error {
    font-size: 14px;
}

.betaText {
    position: absolute;
    left: 12px;
    top: 15px;
    color: #c30000;
    font-family: var(--ion-font-semibold);
    font-size: 16px;
    z-index: 12;
    text-shadow: 0px 2px 6px rgba(255, 255, 255, 0.11);
    margin-top: env(safe-area-inset-top);
}

// modal word break for
.alert-wrapper.sc-ion-alert-md {
    min-width: 310px;
}

.alert-radio-group.sc-ion-alert-md {
    text-overflow: unset !important;
    white-space: unset !important;
    max-height: 280px !important;
    min-width: 310px !important;
}

.alert-tappable.sc-ion-alert-md {
    height: 56px;
}

.alert-radio-label.sc-ion-alert-md {
    text-overflow: unset !important;
    white-space: unset !important;
    padding-top: 3px;
    padding-bottom: 3px;
}

.error_msg {
    line-height: 1.3;
    font-size: 14px;
    margin: 5px 0px 0px 0px;
}

// Offer & Event Details page Css
.body-part {
    background: transparent !important;

    .body-text-part {
        img {
            margin: 20px auto;
            display: flex;
            justify-content: center;
            max-width: 60px;
        }

        font-size: 14px !important;
        font-family: var(--ion-font-regular);
        color: var(--ion-color-medium-tint) !important;
        text-align: left !important;
        padding: 20px !important;
        padding-top: 10px !important;
        line-height: 18px;

        h3 {
            font-family: var(--ion-font-semibold);
            color: var(--ion-text-color);
            font-size: 20px !important;
        }

        h4 {
            font-family: var(--ion-font-regular);
            color: var(--ion-text-color);
            font-size: 18px;
            margin: 15px 0;
        }

        p {
            font-size: 14px !important;
            font-family: var(--ion-font-regular);
            color: var(--ion-color-medium-tint) !important;
            //text-align: left !important;
            line-height: 18px;
        }
    }
}

.alert-button.sc-ion-alert-md {
    text-transform: capitalize;
}

.refresher-pulling,
.refresher-refreshing {
    z-index: 1232151;
}

ion-input,
ion-texteare,
ion-select {
    font-family: var(--ion-font-regular);
}

.btn-default {
    height: 50px;
    margin: 10px;
    font-size: 16px;
    font-family: var(--ion-font-semibold);
}

.primary-gradient {
    --background: linear-gradient(20deg, var(--ion-color-primary-shade) 0%, var(--ion-color-primary-tint) 110%);
    --background-hover: linear-gradient(20deg, var(--ion-color-primary-shade) 0%, var(--ion-color-primary-tint) 110%);
    --background-activated: linear-gradient(20deg,
            var(--ion-color-primary-shade) 0%,
            var(--ion-color-primary-tint) 110%);
    --background-focused: linear-gradient(20deg, var(--ion-color-primary-shade) 0%, var(--ion-color-primary-tint) 110%);
    color: var(--ion-color-primary-contrast);

    &.activated {
        --background-activated: linear-gradient(20deg,
                var(--ion-color-primary-shade) 0%,
                var(--ion-color-primary-tint) 110%);
        color: var(--ion-color-primary-contrast);
    }

    &.button-disabled {
        --background: var(--ion-color-disabled);
        color: var(--ion-color-primary-contrast);
        --opacity: 1;
    }
}

.dark-gradient {
    --background: linear-gradient(191.19deg, var(--ion-color-dark-tint), var(--ion-color-dark-shade));
    color: var(--ion-color-dark-contrast);
    --background-hover: linear-gradient(191.19deg, var(--ion-color-dark-tint), var(--ion-color-dark-shade));
    --background-activated: linear-gradient(191.19deg, var(--ion-color-dark-tint), var(--ion-color-dark-shade));
    --background-focused: linear-gradient(191.19deg, var(--ion-color-dark-tint), var(--ion-color-dark-shade));

    &.activated {
        --background-activated: linear-gradient(191.19deg, var(--ion-color-dark-tint), var(--ion-color-dark-shade));
        color: var(--ion-color-dark-contrast);
    }

    &.button-disabled {
        --background: var(--ion-color-disabled);
        color: var(--ion-color-dark-contrast);
        --opacity: 1;
    }
}

.light-gradient {
    --background: linear-gradient(81deg, var(--ion-color-light-shade), var(--ion-color-light-tint));
    color: var(--ion-color-light-contrast);
    color: #474747;
    --opacity: 1;

    &.activated {
        --background-activated: linear-gradient(81deg, var(--ion-color-light-shade), var(--ion-color-light-tint));
        color: var(--ion-color-light-contrast);
        color: #474747;
    }

    &.button-disabled {
        --background: var(--ion-color-disabled);
        color: var(--ion-color-primary-contrast);
    }
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.alertCustomCss {
    .alert-head {
        display: none;
    }

    .alert-wrapper {
        width: 87%;
        max-width: 332px;
        border-radius: 10px;
    }

    .alert-message {
        font-size: 14px;
        color: var(--ion-color-medium);
        padding: 40px 25px 30px 25px;
        line-height: 20px;
        max-width: 95%;
        margin: auto;
        font-family: var(--ion-font-regular);
    }

    .alert-button {
        height: 50px;
        font-size: 18px;
        background-color: var(--ion-color-primary);
        color: var(--ion-color-primary-contrast);
        font-weight: normal;
        font-family: var(--ion-font-semibold);
        border: 0;
    }
}

.comming-soon {
    padding: 25px;
    text-align: center;

    h2 {
        font-size: 25px;
        margin: 0px;
        color: var(--ion-color-primary);
        font-family: var(--ion-font-semibold);
    }
}

.tooltip {
    font-family: var(--ion-font-regular);
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    letter-spacing: normal;
    background: grey !important;
    text-align: left !important;

    &.tooltip-light {
        background: #fff !important;
    }
}

.tooltip-left::after {
    border-color: transparent transparent transparent grey !important;
}

.tooltip-right::after {
    border-color: transparent grey transparent transparent !important;
}

.tooltip-top::after {
    border-color: grey transparent transparent !important;
}

.tooltip-bottom::after {
    border-color: transparent transparent grey !important;
}

ion-loading {
    --spinner-color: var(--ion-color-primary) !important;
}

.loaderIcon {
    width: 50px;
    display: block;
    margin: 0 auto 10px;
}

.highcharts-credits {
    display: none !important;
}

.wrapper {
    .otp-input {
        width: calc(25% - 10px) !important;
        height: 36px !important;
        border: 0px !important;
        font-size: 18px !important;
        font-family: var(--ion-font-regular) !important;
        border-bottom: 1px solid rgb(var(--ion-color-medium-tint-rgb, 155, 155, 155), 0.3) !important;
        background: transparent !important;
        border-radius: 0px !important;
        margin-left: 10px !important;
        margin-right: 0px !important;

        &:focus {
            outline: none;
            box-shadow: none;
        }
    }
}

pdf-viewer {
    display: block;
    width: 100% !important;
}

.ng2-pdf-viewer-container {
    width: 100%;
}

// Mobiscroll Theme Light
$mbsc-mobiscroll-frame-background: #ffffff; //The Eventcalendar background color
$mbsc-mobiscroll-frame-text: #c99700; //The Eventcalendar text color
$mbsc-mobiscroll-frame-accent: #c99700; //The Eventcalendar accent color
$mbsc-mobiscroll-calendar-mark: #c99700;
// Mobiscroll Theme Dark
$mbsc-mobiscroll-frame-accent: #c99700; //The Eventcalendar accent color
$mbsc-mobiscroll-calendar-mark: #c99700;
$mbsc-ios-accent: #c99700;
$mbsc-material-accent: #c99700;
$mbsc-mobiscroll-accent: #c99700;
@import "~@mobiscroll/angular/dist/css/mobiscroll.scss";

.dateTime .mbsc-ios.mbsc-form-control-wrapper {
    margin: 0px;
    border: 0px;
    background: transparent;
}

.dateTime .mbsc-ios.mbsc-textfield {
    padding: 0px;
    padding-left: 30px;
    height: 2.5em;
}

.dateTime .mbsc-ios.mbsc-textfield-inner {
    font-size: 14px;
}

.dateTime .mbsc-ios.mbsc-form-control-wrapper:before,
.dateTime .mbsc-ios.mbsc-form-control-wrapper:after {
    display: none;
}

.mbsc-mobiscroll .mbsc-cal-days>div {
    border-bottom: 0 !important;
    color: #686868 !important;
    font-size: 12px !important;
    font-family: var(--ion-font-semibold);
}

.mbsc-cal-month {
    font-family: var(--ion-font-semibold);
}

.mbsc-fr-w {
    font-family: var(--ion-font-regular) !important;
}

.mbsc-mobiscroll .mbsc-cal-day-date {
    font-size: 14px !important;
}

.mbsc-cal-day {
    color: #000;
}

.e-tooltip-wrap {
    filter: none;
    visibility: visible;
    height: auto;
    position: absolute;
    width: auto;
    z-index: 1000;
    max-width: 240px;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 8px;
    pointer-events: none;
    font-family: var(--ion-font-regular);
    font-size: 14px;
    line-height: 16px;
    font-weight: normal;
    letter-spacing: normal;
    background: #808080;
    text-align: left;
    box-shadow: 0 7px 15px -5px #0006;
}

.e-popup.e-popup-open {
    display: block;
}

.e-tooltip-wrap .e-arrow-tip-inner.e-tip-right,
.e-tooltip-wrap .e-arrow-tip-inner.e-tip-left,
.e-tooltip-wrap .e-arrow-tip-inner.e-tip-bottom,
.e-tooltip-wrap .e-arrow-tip-inner.e-tip-top {
    color: #808080;
    font-size: 16px;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    text-transform: none;
}

.e-tooltip-wrap .e-arrow-tip {
    overflow: hidden;
    position: absolute;
}

.e-tooltip-wrap .e-arrow-tip-outer {
    height: 0;
    left: 0;
    position: absolute;
    top: 0;
    width: 0;
}

.e-tooltip-wrap .e-arrow-tip.e-tip-right {
    height: 16px;
    left: 100%;
    top: 50%;
    width: 8px;
}

.e-tooltip-wrap .e-arrow-tip.e-tip-left {
    height: 16px;
    left: -9px;
    top: 48%;
    width: 8px;
}

.e-tooltip-wrap .e-arrow-tip.e-tip-bottom {
    height: 8px;
    left: 50%;
    top: 100%;
    width: 16px;
}

.e-tooltip-wrap .e-arrow-tip.e-tip-top {
    height: 8px;
    left: 50%;
    top: -9px;
    width: 16px;
}

.e-tooltip-wrap .e-arrow-tip-outer.e-tip-bottom {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #808080;
}

.e-tooltip-wrap .e-arrow-tip-outer.e-tip-top {
    border-bottom: 8px solid #808080;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
}

.e-tooltip-wrap .e-arrow-tip-outer.e-tip-left {
    border-bottom: 8px solid transparent;
    border-right: 8px solid #808080;
    border-top: 8px solid transparent;
}

.e-tooltip-wrap .e-arrow-tip-outer.e-tip-right {
    border-bottom: 8px solid transparent;
    border-left: 8px solid #808080;
    border-top: 8px solid transparent;
}

ion-select::part(icon) {
    display: none;
}

.customToggle {
    --background: #fff;
    --background-checked: #fff;
    --handle-background: #b0b0b0;
    --handle-background-checked: var(--ion-color-primary);
    --handle-width: 15px;
    --handle-height: 15px;
    --handle-box-shadow: none;
    --handle-spacing: 3px;
    height: 21px;
    border-radius: 50px;
    border: 1px solid #b0b0b0;
    max-width: 42px;

    &.toggle-checked {
        border-color: var(--ion-color-primary);
    }
}

ion-accordion.accordion-animated>[slot="header"] .ion-accordion-toggle-icon {
    color: var(--ion-color-primary);
    font-size: 24px;
}

.swiper-button-prev,
.swiper-button-next {
    transform: translateY(-50%);
    margin: 0px;
    background: none;
    width: 20px;
    height: 30px;
}

.position-relative {
    position: relative;
}

.m-0 {
    margin: 0px;
}

.mt-0 {
    margin-top: 0px;
}

.mb-0 {
    margin-bottom: 0px;
}

.ml-0 {
    margin-left: 0px;
}

.mr-0 {
    margin-right: 0px;
}

.dateItemWrap .dateTime .mbsc-ios.mbsc-textfield {
    padding-left: 25px;
}

.radioItem {
    color: var(--ion-color-medium-tint);

    ion-label {
        margin: 0px;
        color: var(--ion-color-medium-tint) !important;
        font-size: 14px !important;
    }

    ion-radio {
        position: absolute;
        left: 0px;
        opacity: 0;
    }

    &.item-radio-checked {
        color: var(--ion-text-color);

        ion-label {
            color: var(--ion-text-color) !important;
        }
    }
}

.uploadPicActionSht {
    .action-sheet-md {
        .action-sheet-title {
            text-align: center;
            color: var(--ion-text-color);
        }

        .action-sheet-button {
            background-image: linear-gradient(76deg, var(--ion-color-primary-shade), var(--ion-color-primary-tint));
            min-height: unset;
            color: var(--ion-color-primary-contrast);
        }
    }

    .uploadButtonCommon {
        background-repeat: no-repeat !important;
        padding-inline-start: 60px !important;
        background-position: 5% 50% !important;
        background-size: 25px !important;
        font-weight: 600;
        color: var(--ion-color-primary) !important;

        &.gallery {
            background-image: url("assets/icon/galleryNew.svg") !important;
        }

        &.camera {
            background-image: url("assets/icon/camera.svg") !important;
        }

        &.delete {
            background-image: url("assets/icon/cross.svg") !important;
        }
    }

    .uploadButton {
        border-radius: 7px;
        height: 60px;
        margin-bottom: 15px;
    }
}

.goesWell .customSearchbar2 .item-searchbar .searchbar-input {
    padding: 6px 6px 6px 0px !important;
    height: 27px;
}

#js-licensing {
    display: none;
}

.errorContainer {
    padding: 0px 16px;

    ion-label {
        color: var(--ion-color-danger) !important;
        display: flex !important;
        align-items: center;
        width: 100%;
        margin: 2px;

        ion-icon {
            margin-right: 5px;
        }
    }

    &.noPadding {
        padding: 0px;
    }
}

ion-popover {
    --max-height: 200px;

    ion-content {
        --padding-start: 8px;
        --padding-end: 8px;
        --padding-top: 5px;
        --padding-bottom: 5px;
        --background: #808080;
        --color: #fff;
        font-size: 14px;
    }

    &::part(content) {
        --background: #808080;
    }

    &.select-popover {
        &::part(content) {
            border-radius: 0px 0px 4px 4px;
        }
    }

    &::part(arrow) {
        // --background: #808080;
        background: #808080;
        display: none;
    }

    ion-select-popover {
        ion-list {
            padding: 0 !important;

            .select-interface-option {
                --min-height: 38px
            }
        }
    }
}

.for-web {
    display: none;
}

.backButton {
    // max-width: 100px;
    --padding-start: 20px;
    --border-radius: 99px;
    height: 32px;
    margin: 0 0 10px 0;

    ion-icon {
        color: var(--ion-color-primary);
    }
}

.item-interactive-disabled.sc-ion-label-ios-h:not(.item-multiple-inputs),
.item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-ios-h,
.informationItem .input-text.select-disabled {
    opacity: 1;
}

@media (min-width: 767px) {

    button,
    ion-button {
        cursor: pointer;
    }

    ion-split-pane {
        --border: 0;
        --side-min-width: 248px;
        --side-max-width: 248px;

        ion-menu {
            position: absolute;
            z-index: 999999;
            top: 56px;
            left: 50%;
            margin-left: -368px;

            ion-header {
                display: none;
            }
        }
    }

    .for-web,
    ion-menu-button {
        display: block;
    }

    .for-mobile {
        display: none;
    }

    .cursor {
        cursor: pointer;
    }

    ion-header ion-toolbar,
    ion-footer,
    .page-content,
    .container {
        max-width: 730px;
        margin: 0 auto;
    }

    ion-content {
        --background: #f2f2f2;
    }

    ion-header {
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
    }

    .tab-content {
        margin-top: 20px;

        ion-header {
            display: none;
        }

        ion-content {
            height: 80vh;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        background: none;
        color: #767676;
        --border-radius: 0px;
    }

    .swiper-button-prev {
        left: -12px;
    }

    .swiper-button-next {
        right: -12px;
    }

    .welcomepage .swiper-container-horizontal>.swiper-pagination-bullets,
    .welcomepage .swiper-pagination-custom,
    .welcomepage .swiper-pagination-fraction {
        bottom: 5px;
    }

    .page-title {
        font-size: 22px;
        color: #000;
        margin-top: 0;
    }

    .mambers-list,
    .fixed-width {
        max-width: 460px;
        margin: 0;
    }

    .modal-default {
        --height: auto;
        --width: 420px;
        // --max-height: 90%;
        --overflow: auto;

        &.pdfModal {
            --width: 600px;
        }

        &.selectMembershipModal {
            --width: 350px;
            --border-radius: 10px;
        }

        &.assignTableModal {
            --width: 506px;
            --background: #F2F2F2;
            --border-radius: 0px;
            --border-width: 0px;
            --max-height: 85vh;
            --min-height: 640px;
        }

        .celebBackground {
            padding: 0;
        }

        &.deleteAlert {
            --width: 390px;
            --border-radius: 4px;
        }
    }

    .modalWrap {
        overflow-y: hidden;
    }

    .customModal {
        display: none;

        &.show {
            display: block;
        }

        .customModal-dialog {
            max-width: 390px;
        }
    }

    .modalBg {
        padding-top: 0;
        background: transparent;
    }

    .custom-searchbar {
        padding: 8px 0px;
        --box-shadow: none;

        .searchbar-input-container {
            border: solid 1px #bcbcbc;
            border-radius: 4px;
        }
    }

    .searchIcon {
        right: 0px;
        background: transparent;
        border: 0px;
        height: 45px;

        ion-icon {
            font-size: 20px;
            color: #979797;
        }
    }

    .right-content {
        .tab-content {

            .default-card,
            .btn-default {
                width: 100%;
            }
        }
    }

    .modalButton {
        margin-bottom: -1px !important;
    }

    .menuContent {
        .menuSearchItem {
            ion-icon {
                margin-top: 8px;
                margin-bottom: 0;
                margin-right: 12px;
            }

            ion-label {
                margin-top: 8px;
                margin-bottom: 0px;
            }
        }

        .menuGroup {
            border-top: 2px solid #EDEDED;
            padding-top: 12px;
            margin-top: 8px;

            ion-item-divider {
                border-width: 0px;

                ion-label {
                    font-size: 16px !important;
                    color: #C4C4C4 !important;
                    font-family: var(--ion-font-reguler);
                    margin: 0;
                }
            }

            ion-item {
                --padding-start: 28px;
            }
        }

        ion-item {
            --min-height: auto;
            --border-color: #EDEDED !important;

            ion-label {
                font-size: 18px !important;
                color: #212121 !important;
                margin: 3px 0;
                font-family: var(--ion-font-reguler);
            }

            &.item-lines-full {
                --border-width: 0px 0px 2px 0px;
            }

            &.menuName {
                ion-label {
                    font-size: 22px !important;
                    font-family: var(--ion-font-reguler);
                }
            }
        }
    }
}

.form-item {
    .customSearch {
        width: 100%;
    }

    .custom-searchbar {
        --border-radius: 4px;
        --background: #fff !important;
        color: var(--ion-text-color);
        font-family: var(--ion-font-semibold);
        border: 1px solid #E8E8E8;
        padding: 0;

        .searchbar-input-container {
            border: 0px;
            height: 36px
        }

        .searchbar-input.sc-ion-searchbar-md {
            height: 36px;
            font-size: 14px;
        }
    }


}

.transparentModal {
    --background: transparent;
    --backdrop-opacity: 0;
}

// .reservationCharges {
//     .ion-accordion-toggle-icon {
//         display: none;
//     }
// }

.tableNumberSelect {
    max-width: 100%;
    padding-left: 0;
    padding-right: 10px;
    font-family: var(--ion-font-regular);
    padding-top: 0px;
    padding-bottom: 0px;
    font-size: 18px;
    border-bottom: 1px solid #ebebeb;
    min-height: 30px;
}